import React from 'react';
import { ApolloProvider } from '@apollo/client';
import CreateApolloClient from './ApolloClient';
import { useAuth0 } from '@auth0/auth0-react';

const ApolloWrapper = ({ element }) => {
    const { getAccessTokenSilently } = useAuth0();
    const client = CreateApolloClient(getAccessTokenSilently);

    return (
        <ApolloProvider client={client}>
            {element}
        </ApolloProvider>
    );
};

export default ApolloWrapper;