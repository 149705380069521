import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain="dev-6xbk84hmivklgqwy.us.auth0.com"
            clientId="ynOoM4vSpDHfdrMsxvV2Z0nU0lyhvKpj"
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: "https://www.pixelvalley.ai/",
                scope: "openid profile"
            }}

        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
