import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LogoutButton = () => {
    const { logout } = useAuth0();

    const handleLogout = () => {
        const returnTo = window.location.pathname + window.location.search;
        logout({
            returnTo: window.location.origin + returnTo
        });
    };

    return (
        <button className="btn btn-custom btn-lg page-scroll"
            onClick={handleLogout}>
            Log Out
        </button>
    );
};

export default LogoutButton;