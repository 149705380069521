import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import Profile from "./Profile";

import { useAuth0 } from "@auth0/auth0-react";

import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

export const ProfileButton = (props) => {

    const {
        user,
        isAuthenticated,
    } = useAuth0();

    return (
        <div>
            {!isAuthenticated && (
                <LoginButton />
            )}
            {isAuthenticated && (
                <UncontrolledDropdown inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                        <img
                            src={user.picture}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                            width="50"
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header><Profile /></DropdownItem>
                        <DropdownItem header><LogoutButton /></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </div>
    );
};
