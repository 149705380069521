import { gql, useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import ConversationScreen from '../Simulation/Conversation/ConversationScreen';
import { useParams } from 'react-router-dom';
import ChatScreen from '../Simulation/Conversation/ChatScreen';
import { useEffect } from 'react';

const GET_CONVERSATION = gql`
    query Conversation($conversationId: ID!)
    {
        conversation(id: $conversationId)
        {
            conversation
            {
                _id
                summary
                dialogue
                {
                    agentName
                    text
                }
                location
                {
                    resizedImageInteriorFilename
                }
                scenario
                {
                    imageFilename
                }
                initiatingAgent
                {
                    agentLocation
                    {
                        location
                        {
                            resizedImageInteriorFilename
                        }
                        scenario
                        {
                            imageFilename
                        }
                    }
                }
                agents
                {
                    _id
                    name
                    agentDescription
                    {
                        portraitFilename
                    }
                }
            }
        },
    }`

const Conversation = (props) => {

    useEffect(() => {
        // Dynamically import the CSS file for the Bootstrap theme
        import('bootswatch/dist/slate/bootstrap.min.css').then(() => {
            console.log('Slate theme loaded');
        });

        // Clean up: optionally remove the theme when the component is unmounted
        return () => {
            const link = document.querySelector('link[href="bootswatch/dist/slate/bootstrap.min.css"]');
            if (link) {
                link.remove();
            }
        };
    }, []);

    let { id } = useParams();

    const { loading, error, data } = useQuery(GET_CONVERSATION, {
        variables: { conversationId: id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <>
                {props.isChat ? (
                    <ChatScreen conversation={data.conversation.conversation} />
                ) : (
                    <ConversationScreen conversation={data.conversation.conversation} />
                )}
            </>
        )
    }
}

export default Conversation;