import React from "react";
import { ProfileButton } from "../../Profile/ProfileButton";

export const Navigation = (props) => {

    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        Pixel Valley
                    </a>{" "}
                </div>
                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#features" className="page-scroll">
                                Features
                            </a>
                        </li>
                        <li>
                            <a href="#portfolio" className="page-scroll">
                                Play!
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="page-scroll">
                                Contact
                            </a>
                        </li>
                        <li>
                            <ProfileButton />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
