
import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { Navigation } from "./Navigation";
import { Header } from "./Header";
import { Features } from "./Features";
import { Gallery } from "./Gallery";
import { Contact } from "./Contact";

const LandingPage = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    useEffect(() => {
        const link1 = document.createElement('link');
        link1.rel = 'stylesheet';
        link1.href = `${process.env.PUBLIC_URL}/css/bootstrap.min.css`;
        document.head.appendChild(link1);

        const link2 = document.createElement('link');
        link2.rel = 'stylesheet';
        link2.href = `${process.env.PUBLIC_URL}/css/style.css`;
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link1);
            document.head.removeChild(link2);
        };
    }, []);

    return (
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <Gallery data={landingPageData.Gallery} />
            <Contact data={landingPageData.Contact} />
        </div>
    );
};

export default LandingPage;
