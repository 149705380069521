import React from 'react';
import GameWindowSize from '../../GameWindowSize';
import OverworldLocations from './OverworldLocations';
import OverworldCharacters from './OverworldCharacters';
import OverworldLabel from './OverworldLabel';
import NewspapersButton from './NewspapersButton';
import { ProfileButton } from '../../Profile/ProfileButton';
import { useEffect } from 'react';

const OverworldBackground = (props) => {

    const gameWindowSize = GameWindowSize({ width: 2048, height: 1024 });

    useEffect(() => {
        // Dynamically import the CSS file for the Bootstrap theme
        import('bootswatch/dist/slate/bootstrap.min.css').then(() => {
            console.log('Slate theme loaded');
        });

        // Clean up: optionally remove the theme when the component is unmounted
        return () => {
            const link = document.querySelector('link[href="bootswatch/dist/slate/bootstrap.min.css"]');
            if (link) {
                link.remove();
            }
        };
    }, []);

    //Get teh location of the outside cahracters
    let currentY = 0
    if (props.scenario.locations.length >= 9) {
        currentY = gameWindowSize.height * 0.72
    }
    else {
        currentY = gameWindowSize.height * 0.6
    }

    //TODO: clean up css, don't use inline
    const divStyle = {
        position: 'relative'
    };

    const backgroundStyle = {
        position: 'absolute',
        zIndex: 1,
        //filter: 'grayscale(20%) brightness(45%) blur(2px)'
    };

    return (
        <div style={divStyle}>
            <div>
                <img
                    src={`https://dyifmflum502e.cloudfront.net/${props.scenario.imageFilename}`}
                    width={gameWindowSize.width}
                    height={gameWindowSize.height}
                    style={backgroundStyle}
                    alt="Background">
                </img>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 32,
                        marginTop: 32,
                        marginLeft: 32,
                        left: 0,
                        top: 0,
                    }}>
                    <ProfileButton />
                </div>
                <div className="d-flex justify-content-center container pt-4">
                    <OverworldLabel
                        scenario={props.scenario} />
                </div>
                <NewspapersButton
                    width={gameWindowSize.width}
                    scenario={props.scenario} />
            </div>
            <OverworldLocations
                scenario={props.scenario}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier} />
            <OverworldCharacters
                scenario={props.scenario}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier}
                y={currentY} />
        </div>
    );
}

export default OverworldBackground;