import React from "react";
import { useNavigate } from 'react-router-dom';

export const Image = ({ title, description, id, largeImage, smallImage }) => {
    const navigate = useNavigate();

    return (
        <div className="portfolio-item">
            <div className="hover-bg">
                {" "}
                <a
                    onClick={() => navigate(`/scenario/${id}`)}
                    //href={'https://pixelvalleyreactapp-d7e17376303e.herokuapp.com/scenario/' + id}
                    title={title}
                    data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                        <h4>{title}</h4>
                        <p>{description}</p>
                    </div>
                    <img src={smallImage} className="img-responsive" alt={title} />{" "}
                </a>{" "}
            </div>
        </div>
    );
};
