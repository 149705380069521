//import 'bootswatch/dist/slate/bootstrap.min.css';
import './App.css';
import Overworld from './Queries/GetOverworld';
import Location from './Queries/GetLocation';
import Conversation from './Queries/GetConversation';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './Profile/Auth0ProviderWithHistory';
import LandingPage from './LandingPage/components/LandingPage';
import ApolloWrapper from './ApolloWrapper';


function App() {

    return (
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <Routes>
                    <Route exact path="/" element={
                        <LandingPage />
                    }
                    />
                    <Route exact path="/scenario/:id" element={
                        <ApolloWrapper element={
                            <Overworld />
                        } />}
                    />
                    <Route path="/location/:id" element={
                        <ApolloWrapper element={
                            <Location />
                        } />}
                    />
                    <Route path="/conversation/:id" element={
                        <ApolloWrapper element={
                            <Conversation isChat={false} />
                        } />}
                    />
                    <Route path="/chat/:id" element={
                        <ApolloWrapper element={
                            <Conversation isChat={true} />
                        } />}
                    />
                </Routes>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    );
}

//Elves 65eccd03635456e5173ad235
//NeoTokyo 65ff27fb6e43ac4559f147fc
//Pemberley 66004b13006cd24fff3ea55a
//Fishing Village 6603768518e6fc04d166200e
//Androids 660392f318e6fc04d16621ed
//Atlantis 66044d8e18e6fc04d16623c2

export default App;
